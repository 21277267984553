<template>
	<div class="p-grid">
		<div class="p-col-12">
			<div class="card">
				<h5>404</h5>
				<p>Use this page to start from scratch and place your custom content.</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data(){
		return {
			data: undefined,
		}
	},
	created(){

	},
	mounted(){

	},
	methods: {

	},
	computed: {

	},
	mixins: [],
	components: {

	}
}
</script>

<style scoped>

</style>
